import React from 'react';
import '../assets/sass/sassy/greenroom.scss';
import gr from '../assets/images/Screenshot from 2021-06-04 15-23-04.png';
function memepto() {
  return (
    <div>
      <div className="greenroom">
        <div className="greenroom__background greenroom">
          <div className="greenroom__box">
            <div className="greenroom__background">
              <a href="https://mcruzlehardy.dev">
                <button className="greenroom__button">Back</button>
              </a>
              <h3 className="greenroom__title">Memepto</h3>
            </div>
            <div className="greenroom__container">
              <img src={gr} className="greenroom__img" alt="Landing Page" />
              <div>
                <div className="greenroom__babyBlue">
                  <h3 className="greenroom__smallTitle">Status:</h3>
                  <h5 className="greenroom__smallTitle">Completed</h5>
                </div>
                <div className="greenroom__babyBlue">
                  <h3 className="greenroom__smallTitle">Tech Stack</h3>
                  <p className="greenroom__smallTitle">
                    React.js, SASS, Axios, BootStrap, CoinGecko API, Git
                  </p>
                </div>
              </div>
            </div>
            <div className="greenroom__teal">
              <h3 className="greenroom__smallTitle--desc">Description</h3>
              <p className="greenroom__smallTitle">
                Memepto was a 24 hour pair programming hackaton I had to do with
                one of my cohort classmates we were allowed to pick the app
                completely on our own and we decided to have some fun with it
                and make it into something kind of goofy but also have some
                intresting features we used an api to collect the top 8 trending
                cryptocurrencies that updates in real time and then we had a
                different page for crypto related memes.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memepto;
